.login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.data-display{
    height: auto;
    background-image: linear-gradient(to right, hwb(163 68% 22%), #abebe4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.card-container {
    margin-top: 1%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background-color: rgb(224, 255, 253);
    border: 1px solid #797777;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
}

/* Login Page  */


.WholeLoginScreen {
    background: linear-gradient(to right, #40bc9b, #e3973f);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoginPage {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    display: flex;
    background-color: rgba(189, 215, 201, 0.763);
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 50vh;
    width: 30vw;
    gap: 30px;
    border-radius: 15px;
    overflow: auto;
}

.LoginHeading {
    font-size: 25px;
}

.InputBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#InputBox1,
#InputBox2 {
    height: 6vh;
    width: 20vw;
    border-radius: 8px;
    border: none;
    text-align: center;
}

#InputBox2 {
    margin-top: 5px;
}

#LoginButton {
    height: 6vh;
    width: 20vw;
    border-radius: 8px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s;
}

#LoginButton:hover {
    background-color: rgba(28, 125, 142, 0.763);
}

#LoginButton:active {
    transform: translateY(2px);
}

