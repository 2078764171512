/*******************************************************/
/*               ScrollBar Styling                     */
/*******************************************************/

::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}




/*******************************************************/
/*         Price Detail Sheet Component Styling        */
/*******************************************************/

.ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalContent {
    background-color: white;
    width: 50rem;
    height: 25rem;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
}

.CloseButton {
    position: absolute;
    top: 12px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.PriceDetails2 {
    margin-top: 1rem;
}





/*******************************************************/
/*               Main Component Styling              */
/*******************************************************/

.Main {
    /* background-color:  #047c89; */
    background-color: rgb(3, 117, 125);
    /* background-color: rgb(185, 185, 201); */
    height: auto;
    width: 92vw;
    margin-left: 4vw;
    margin-top: 1vh;
    margin-right: 5vw;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.LeftContainer {
    flex: 0.3;
    display: flex;
    flex-direction: column;
}

.RightContainer {
    flex: 0.7;
}

.AllComponentsRenderMaster {
    /* background-color: rgb(43, 63, 104); */
    background-color: #c8c8c8;
    height: auto;
    width: 70%;
    margin-top: 8%;
    margin-right: 10%;
    margin-left: 15%;
    margin-bottom: 8%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.AllComponentsRenderHere {
    background-color: rgb(185, 185, 201);
    height: 66vh;
    width: 96%;
    border-radius: 20px;
    margin-top: 2%;
    margin-bottom: 2%;
}


.NameAndLogo {
    margin-top: 10%;
    color: aliceblue;
    margin-bottom: 10%;
    font-size: large;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: larger;
    font-weight: 700;
}

.NameAndLogo img {
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
}

.NameAndLogo span {
    /* padding-left: 15px; */
}

.LeftContainerButtons {
    height: 42%;
    width: 65%;
    margin-top: 15%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;
}


.LeftContainerButtons button {
    height: 50px;
    border-radius: 10px;
    font-size: large;
    overflow: hidden;
    cursor: pointer;
}

.LeftContainerButtons button:hover {
    background-color: rgb(201, 194, 206);
}



.BackAndMoreButton {
    margin-right: 5%;
    margin-top: 2%;
}

.MoreButtonContainer:hover {
    background-color: #d0b9b9;
}

.BackButtonContainer:hover {
    background-color: #d0b9b9;
}


.MoreContainer {
    position: relative;
}

.MoreContainer button {
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 16px;
    color: #000000;
}

.BackButtonContainer {
    height: 5%;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    border: 1px solid black;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 16px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdown-menu button {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdown-menu button:hover {
    background-color: #bcbcbc;
}



/*******************************************************/
/*               Welcome Component Styling              */
/*******************************************************/


.WelcomeComponent {
    margin-top: 1%;
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    overflow: auto;
}


.WelcomeHeading {
    font-size: x-large;
}

.LeftHandPng img {
    margin-top: 5%;
    height: 80px;
    width: 100px;
}

.DescriptionBox {
    /* background-color: rgb(133, 133, 133); */
    background-color: rgb(3, 117, 125);
    height: 10%;
    width: 90%;
    border-radius: 18px;
    margin-top: 4%;
    overflow: auto;
    color: white;
}

.DescriptionBox span {
    margin-left: 10%;
    display: flex;
    margin: 5%;
}

.ProvidingServices {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 5%;
}

.AllServices {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
}

.service-item {
    text-align: center;
}

.service-item img {

    margin-top: 10px;
    width: 40%;
    overflow: hidden;
    background-color: rgba(16, 64, 103, 0.874);
    border-radius: 10%;
}

.service-item h5 {
    margin-top: 10px;
}




/*******************************************************/
/*               Hourly Component Styling              */
/*******************************************************/

.HourlyDriverComponent {
    overflow: hidden;
    flex-wrap: wrap;
}

.TripTypeButton {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 7vh;
    margin-bottom: 2%;
    align-items: center;
    border-radius: 15px;
    overflow-y: hidden;
    margin-top: 1%;
}

.TripTypeButton button {
    height: 40px;
    width: 150px;
    border-radius: 15px;
    border: 0;
    font-size: medium;
    cursor: pointer;

}

.TripTypeButton button.active {
    background-color: rgb(46, 78, 165);
    color: white;
}

.CostEstimation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}




.CostPerHour {
    background-color: rgb(218, 227, 227);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 10%;
    padding-left: 5%;
    margin-right: 10%;
    padding-right: 5%;
    border-radius: 15px;
    margin-top: 2%;
    height: 5vh;

}

.Price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    background-color: rgb(218, 227, 227);
    justify-content: space-between;
    margin-left: 10%;
    padding-left: 5%;
    margin-right: 10%;
    padding-right: 5%;
    border-radius: 15px;
    height: 5vh;
    overflow: hidden;
}

.Ammount {
    margin-top: 1%;
}

.ViewButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ViewButton button {
    cursor: pointer;
}

.Form {
    background-color: rgb(218, 227, 227);
    height: 28vh;
    margin-left: 10%;
    padding-left: 5%;
    margin-right: 10%;
    padding-right: 5%;
    border-radius: 15px;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5%;
    overflow: hidden;
}

.NameAndNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    gap: 8%;
    margin-top: 4.5%;
}

.NameAndNumber input {
    border-radius: 5px;
    height: 5.8vh;
    border: 0;
    width: 14vw;
    text-align: center;
}

.DateTimeAndVehicleType {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    gap: 4%;
    margin-top: 2%;
}

.DateTimeAndVehicleType input {
    height: 5.8vh;
    border-radius: 8px;
    border: 0px;
    width: 10.8vw;
    text-align: center;
    margin-top: 4%;
    cursor: pointer;
}

.DateTimeAndVehicleType select {
    height: 5.8vh;
    border-radius: 8px;
    border: 0px;
    margin-top: 4%;
    text-align: center;
}

.HourlyDateAndTime {
    display: flex;
    gap: 5%;
}

.HourlyDateAndTime input {
    margin-top: 0%;
    width: 10vw;
}

.HourlyVehicleType {
    display: flex;
    align-items: center;
    justify-content: center;
}

.HourlyVehicleType select {
    margin-top: 0%;
    width: 9vw;
}

.Addresses {
    display: flex;
    gap: 5.5%;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
}

.Addresses input {
    margin-top: 4%;
    height: 5.8vh;
    width: 14.3vw;
    border-radius: 8px;
    border: 0;
    text-align: center;
}

.SubmitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
}

.SubmitButton button {
    background-color: rgb(218, 227, 227);
    height: 5vh;
    width: 6vw;
    margin-top: 1%;
    border-radius: 12px;
    cursor: pointer;
    overflow: hidden;
}


.Price {
    display: flex;
    justify-content: center;
    align-items: center;
}




/*******************************************************/
/*               Weekly Component Styling              */
/*******************************************************/
.WeeklyCheckBox {
    background-color: rgb(218, 227, 227);
    width: 31.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 8vh;
    border-radius: 15px;
    overflow-y: hidden;
    margin-top: 1%;
    margin-left: 10%;
}


.CheckBoxContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}


.WeeklyDateTimeAndVehicleType {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    gap: 2%;
}

.WeeklyDateTimeAndVehicleType input {
    height: 5.8vh;
    border-radius: 8px;
    border: 0px;
    width: 7.4vw;
    text-align: center;
    margin-top: 4%;
    cursor: pointer;
}

.WeeklyDateTimeAndVehicleType select {
    height: 5.8vh;
    border-radius: 8px;
    border: 0px;
    margin-top: 4%;
    text-align: center;
}



.WeeklyDateTimeAndVehicleType {
    gap: 4%;
}

.WeeklyDates {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5%;
}

.WeeklyTimeAndVehicle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2%;
}

.Price {
    display: flex;
    justify-content: center;
    align-items: center;
}


/*******************************************************/
/*               Monthly Component Styling              */
/*******************************************************/


.MonthlyDateTimeAndVehicleType {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    gap: 2%;
}

.MonthlyDateTimeAndVehicleType input {
    height: 5.8vh;
    border-radius: 8px;
    border: 0px;
    width: 7.4vw;
    text-align: center;
    margin-top: 4%;
    cursor: pointer;
}

.MonthlyDateTimeAndVehicleType select {
    height: 5.8vh;
    border-radius: 8px;
    border: 0px;
    margin-top: 4%;
    text-align: center;
}



.MonthlyDateTimeAndVehicleType {
    gap: 4%;
}

.MonthlyDates {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5%;
}

.MonthlyTimeAndVehicle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2%;
}

.Price {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*******************************************************/
/*            OutStation Component Styling             */
/*******************************************************/

.TripTypeButton button {
    background-color: #ccc;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    outline: none;
}

.TripTypeButton button.selected {
    background-color: #364dab;
    color: #fff;
}



.OutstationDateAndTime {
    display: flex;
    gap: 5%;
}

.OutstationDateAndTime input {
    margin-top: 0%;
    width: 10vw;
}

.OutstationVehicleType {
    display: flex;
    align-items: center;
    justify-content: center;
}

.OutstationVehicleType select {
    margin-top: 0%;
    width: 9vw;
}


.Price {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*******************************************************/
/*               Refer Component Styling              */
/*******************************************************/
.Refer {
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68vh;
}

.InsideRefer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60%;
    width: 80%;
    gap: 7%;
    border: 2px solid rgb(101, 100, 100);
    border-radius: 3%;
}

.ReferBoxComment {
    border-radius: 15px;
    text-align: center;
    height: 30%;
    width: 95%;
    background-color: rgb(224, 208, 185);
    display: flex;
    justify-content: center;
    align-items: center;
}

.MobileInputBox {
    display: flex;
    height: 8%;
    width: 60%;
}

.MobileInputBox input {
    height: 130%;
    width: 110%;
    border-radius: 0.5rem;
    text-align: center;
}







/*******************************************************/
/*               Reviews Component Styling              */
/*******************************************************/
.AllReviews {
    position: relative;
    overflow: auto;
    height: 65vh;
}

.ReviewTaking {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10%;
    margin-top: 1rem;
    background-color: #c8c8c8;
    height: 30%;
    width: 80%;
    border-radius: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
}

#ReviewTextArea {
    border-radius: 0.5rem;
    height: 35%;
    width: 80%;
    text-align: center;
}

.ReviewTaking input {
    height: 10%;
}

#MobileNumberArea {
    height: 20%;
    border-radius: 5px;
    text-align: center;
    width: 40%;
}

.ReviewSubmitButton {
    height: 20%;
    width: 20%;
    border-radius: 5px;
    overflow: hidden;
}

.ReviewsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow: auto; */
}


.Reviews {

    margin-top: 1%;
    background-color: #c8c8c8;
    width: 80%;
    border-radius: 5px;
    padding: 1rem;

}

.ReviewHeader {
    margin: 0.5rem;
}

.ReviewDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ReviewComment {
    margin-top: 1rem;
    margin-left: 1.5%;
}




/*******************************************************/
/*               Track Component Styling              */
/*******************************************************/







/*********************************************/
/*               Footer Section              */
/*********************************************/
.Footer {
    background-color: #cdc1c1;
    width: 92vw;
    height: 8vh;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #170c0c;
}

.ReferIcon,
.ReviewIcon,
.TrackingIcon {
    height: 90%;
    width: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ReferIcon:hover,
.ReviewIcon:hover,
.TrackingIcon:hover {
    background-color: aliceblue;
    border-radius: 10px;
}

.ReferIcon img,
.ReviewIcon img,
.TrackingIcon img {
    height: 80%;
}





/*******************************************************/
/*             Media Control Query                     */
/*******************************************************/

@media screen and (max-width: 768px) {


    .Main {
        flex-direction: column;
        width: 98%;
        justify-content: center;
        align-items: center;
        margin-left: 1%;
        margin-bottom: 0.3%;
        margin-top: 0.3%;
        overflow-y: auto;
    }

    .Form {
        gap: 3.8%;
    }

    .NameAndNumber input {
        width: 32.5vw;
    }

    .NameAndNumber {
        gap: 1%;
    }

    .Addresses {
        gap: 1%;
    }

    .Addresses input {
        width: 32.5vw;
    }

    .DateTimeAndVehicleType input {
        width: 20.5vw;
    }

    .DateTimeAndVehicleType select {
        width: 20vw;
    }

    .Footer {
        width: 98%;
        height: 8vh;
    }

    .LeftContainer {

        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 55vh;
        margin-left: 0;
        height: auto;
    }

    .NameAndLogo {
        margin-top: 4%;
    }

    .LeftContainerButtons {
        margin-top: 3%;
        width: 100%;
    }

    .RightContainer {
        justify-content: center;
        align-items: center;
        width: 96vw;
        height: auto;
        margin-bottom: 1%;
    }

    .LeftContainerButtons {
        margin-left: 1%;
        height: 60%;
        width: 90%;
    }

    .BackAndMoreButton {
        display: none;
    }

    .Refer {
        height: 50vh;
    }

    .ReferIcon,
    .ReviewIcon,
    .TrackingIcon {
        flex-direction: column;
        height: 70%;
    }

    .AllComponentsRenderHere {
        height: auto;
        margin-top: 2%;
        margin-bottom: 2%;
        /* overflow: auto; */
    }

    .AllComponentsRenderMaster {
        margin-left: auto;
        margin-right: auto;
        width: 94%;
        height: auto;
        margin-bottom: 4%;
    }

    .Form input {
        margin-left: 0;
        margin-right: 0;
    }

    .SubmitButton button {
        height: 5vh;
        width: 20vw;
    }

    .WeeklyCheckBox {
        width: 69vw;
    }

    /* Monthly Mobile View Form */


    .MonthlyDateTimeAndVehicleType {
        flex-direction: column;
        width: 100vw;

    }

    .MonthlyDateTimeAndVehicleType input {
        margin-top: 1%;
    }

    .MonthlyDateTimeAndVehicleType select {
        margin-top: 1%;
    }

    .MonthlyDates {
        width: 100%;
        gap: 1%;
        justify-content: center;
        align-items: center;
    }

    .MonthlyDates input {
        width: 32.5%;
        /* height: 100%; */
        height: 5vh;
    }


    .MonthlyTimeAndVehicle {
        width: 100%;
        gap: 1%;
    }

    .MonthlyTimeAndVehicle input,
    .MonthlyTimeAndVehicle select {
        width: 32.5%;
        /* height: 100%; */
        height: 5vh;
    }


    /* Weekly Mobile View Form */


    .WeeklyDateTimeAndVehicleType {
        flex-direction: column;
        width: 100vw;

    }

    .WeeklyDateTimeAndVehicleType input {
        margin-top: 1%;
    }

    .WeeklyDateTimeAndVehicleType select {
        margin-top: 1%;
    }

    .WeeklyDates {
        width: 100%;
        gap: 1%;
        justify-content: center;
        align-items: center;
    }

    .WeeklyDates input {
        width: 32.5%;
        /* height: 100%; */
        height: 5vh;
    }


    .WeeklyTimeAndVehicle {
        width: 100%;
        gap: 1%;
    }

    .WeeklyTimeAndVehicle input,
    .WeeklyTimeAndVehicle select {
        width: 32.5%;
        /* height: 100%; */
        height: 5vh;
    }



    /* Hourly Mobile view form */

    .HourlyDateAndTime {
        gap: 1%;
    }

    .DateTimeAndVehicleType {
        flex-direction: column;

    }

    .HourlyDateAndTime input {
        width: 33vw;
    }

    .HourlyVehicleType select {
        width: 45vw;
        margin-top: 4%;
        height: 4vh;
    }

    /* Outstation Mobile View form */

    .OutstationDateAndTime {
        gap: 1%;
    }

    .DateTimeAndVehicleType {
        flex-direction: column;

    }

    .OutstationDateAndTime input {
        width: 33vw;
    }

    .OutstationVehicleType select {
        width: 45vw;
        margin-top: 4%;
        height: 4vh;
    }
}
